<template>
  <div>
    <vue-plotly :data="data" :layout="layout" :options="options"/>
  </div>
</template>
<script>
  import VuePlotly from '@statnett/vue-plotly'

  export default {
    name: "ComparativeTagBarChart",
    components: {
      VuePlotly
    },
    props:{
      nameA:String,
      nameB:String,
      tagsA:Object,
      tagsB:Object
    },
    data: function () {
      return {
        data: [],
        layout: {barmode: 'group'},
        options: {}
      }
    },
    created() {
      var data = [
        {
          "type": "bar",
          "name": this.nameA,
          "x": this.tagsA.labels,
          "y": this.tagsA.y
        },
        {
          "type": "bar",
          "name": this.nameB,
          "x": this.tagsB.labels,
          "y": this.tagsB.y
        }
      ]

      this.data = data

    }
  }
</script>
<style></style>