<template>
  <div class="recipe-menu tabs-menu">
    <b-card no-body style="border: none;">
      <b-tabs pills card vertical>
        <b-tab v-for="ontology in ontologies" lazy :key="ontology.ontology">
          <template v-slot:title>
            <span>{{ontology.ontology}}</span>
          </template>
          <div v-for="category in ontology.categories" :key="category.name">
            <div>
              {{category.description}}
            </div>
            <TaggedPassageComparison :category="category"
                                     :ontology-data="ontology"
                                     :second-version-data="secondVersionData"
                                     :ontology-name="ontology.ontology"/>
          </div>

        </b-tab>
      </b-tabs>
    </b-card>
  </div>

</template>

<script>
  import LanguageIntelligenceService from '@/modules/languageInteligence/services/LanguageIntelligenceService'
  import TaggedPassageComparison from "@/modules/languageInteligence/views/TaggedPassageComparison";


  export default {
    name: "ProgressiveComparison",
    components: {TaggedPassageComparison},
    data() {
      return {
        ontologies:[],
        comparison:null,
        selectedOntology:null,
        selectedTag:null,
        showTags:true,
        polling: null
      }
    },
    props:{
      firstVersion:{},
      secondVersion:{},
      firstVersionData:{},
      secondVersionData:{},
    },

    created() {
    },
    watch: {
      firstVersionData: {
        deep:true,
        handler() {
          this.showComparison()
        }
      },
      secondVersionData: {
        deep:true,
        handler() {
          this.showComparison()
        }
      },
      comparison: function () {
        this.showComparison()
      },
      firstVersion: function() {
        this.getComparison()
      },
      secondVersion: function() {
        this.getComparison()
      }
    } ,
    methods : {
      getComparison() {
        if (this.firstVersion.doc_id != undefined
          && this.secondVersion.doc_id != undefined ) {
          LanguageIntelligenceService.getDocumentComparison(this.firstVersion.doc_id,
            this.secondVersion.doc_id).then(response => {
              if (response.data.status == "QUEUED") {
                if (this.polling == null) {
                  this.pollData()
                }
              } else {
                clearInterval(this.polling)
                this.polling = null
                this.comparison = response.data
              }
          })
        }
      },
      pollData() {
        this.polling= setInterval(
          (function(self) {         //Self-executing func which takes 'this' as self
            return function() {   //Return a function in the context of 'self'
              self.getComparison(); //Thing you wanted to run as non-window 'this'
            }
          })(this),5000);
      },

      showComparison() {
        if (this.firstVersionData.ready && this.secondVersionData.ready && this.comparison != null) {
          this.ontologies = this.comparison
        }
      },

    }


  }
</script>

<style lang="css">
  .no-imgs img{
    display: none
  }

</style>