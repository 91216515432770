<template>
  <div>
    <b-form-select class="document-select m-auto" v-model="selectedOntology">
      <template v-slot:first>
        <b-form-select-option v-for="ontology in ontologiesA" :key="ontology.name" :value="ontology">
          {{ontology.name}}
        </b-form-select-option>
      </template>
    </b-form-select>
    <vue-plotly :data="data" :layout="layout" :options="options"/>
  </div>
</template>
<script>
  import VuePlotly from '@statnett/vue-plotly'

  export default {
    name: "ComparativeGroupedTagBarChart",
    components: {
      VuePlotly
    },
    props:{
      titleA:String,
      titleB:String,
      ontologiesA:Array,
      ontologiesB:Array
    },
    data: function () {
      return {
        selectedOntology:null,
        layout: {},
        options: {}
      }
    },
    created() {
      this.selectedOntology = this.ontologiesA[0]
    },
    computed: {
      data() {
        if (this.selectedOntology !=null) {
          let ontologyB = null
          let $this = this
          this.ontologiesB.forEach(ontology => {
            if ($this.selectedOntology.name == ontology.name) {
              ontologyB = ontology
            }
          })
          var data = [
            {
              "type": "bar",
              "name": this.titleA,
              "x": this.selectedOntology.graphData.labels,
              "y": this.selectedOntology.graphData.y
            }]
          if (ontologyB != null) {
            data.push(            {
              "type": "bar",
              "name": this.titleB,
              "x": ontologyB.graphData.labels,
              "y": ontologyB.graphData.y
            }
          )
          }
          return data
        } else {
          return []
        }
      }
    }
  }
</script>
<style></style>