<template>
  <div>
    <h3 class="section-title openSans-100 fz-28 summary-title">
      {{currentDocument.title || $t('li.document.document')}}
      <span class="fz-15 ml-4">Version {{currentDocument.version || 1}}</span>
    </h3>
    <h6>{{ $t('li.document.summary') }}</h6>
    <div class="pb-4">
      <div class="row d-flex">
        <span class="text-align-right">
          {{passages}}
        </span>
        <span class="col-10 text-align-left">
          {{ $t('li.document.passages') }}
        </span>
      </div>

      <div v-for="ontologyCount in ontologies" v-bind:key="ontologyCount.name"
           class="row d-flex">
        <span class="text-align-right">
          {{ontologyCount.total}}
        </span>
        <span class="col-10 text-align-left">
          {{ontologyCount.name}} ({{ontologyCount.unique}} unique)
        </span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'document-summary',
  props: {
    currentDocument: {},
    ontologies: Array,
    passages: Number
  }
}
</script>
<style lang="css" scoped>

.text-align-right {
  padding-left: 15px;
  text-align: right;
  font-weight: bolder;
  color: darkblue;
}
.summary-title {
  min-height: 100px;
}

</style>