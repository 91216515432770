<template>
  <div>
    <div class="graph-title"> {{title}}
    </div>
    <vue-plotly :data="data" :layout="layout" :options="options"/>
  </div>
</template>
<script>
  import VuePlotly from '@statnett/vue-plotly'

  export default {
    name: "ZoomableSunburstChart",
    components: {
      VuePlotly
    },
    props:{
      title:String,
      tags:Object,
      size:Number
    },
    data: function () {
      return {
        data: [],
        layout: {},
        options: {}
      }
    },
    created() {
      let labels = this.tags.labels
      let parents = this.tags.parents
      let values= this.tags.values
      let ids = this.tags.ids

      var data = [
        {
          "type": "sunburst",
          "labels": labels,
          "parents": parents,
          "values":  values,
          "ids":  ids,
          "leaf": {"opacity": 0.4},
          "marker": {"line": {"width": 2}},
          "branchvalues": 'total'
        }]

      let size = this.size
      if (!size) {
        size = 500
      }

      var layout = {
        "margin": {"l": 0, "r": 0, "b": 0, "t": 0},
        sunburstcolorway: this.tags.colors,
        width: size,
        height: size
      }
      this.data = data
      this.layout = layout

    }
  }
</script>
<style scoped>
  .graph-title {
    min-height: 50px;
  }
</style>