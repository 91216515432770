<template>
  <div>
    <p>{{ title }}</p>
    <p>
        <b-form-select class="document-select w-100" :value="currentDocument" ref="doc"
                       @change="handleInput($event)" :disabled="!enabled">
        <template v-slot:first>
          <b-form-select-option :value="null" disabled>-- Please select a document--</b-form-select-option>
          <b-form-select-option v-for="document in filteredDocuments" :key="document.doc_id" :value="document">
            {{document.title}} - version {{document.version || 1}}
          </b-form-select-option>
        </template>
      </b-form-select>
    </p>
  </div>
</template>
<script>
export default {
  name: 'select-docs',
  props: {
    currentDocument: {},
    blacklistedDocument:Object,
    documents: Array,
    title: String,
    enabled: Boolean
  },

  methods: {
    handleInput (e) {
      this.$emit('input', e)
    }
  },
  computed:{
    filteredDocuments(){
      let docs = []
      let $this = this
      this.documents.forEach(element => {
        if (element != $this.blacklistedDocument) {
          docs.push(element)
        }
      })
      return docs
    }
  }
}
</script>
<style lang="css">

.document-select {
  width: 40%;
  color: #28a745;
  border-color: #28a745;
}

</style>