<template>
  <div class="container-base section-container-padding bg-white language-intelligence-document-fonts">
    <div class="myproj-container">
      <b-tabs content-class="mt-0">
        <b-tab active>
          <template v-slot:title>
            <img :src="'/assets/images/icon_di_ingestedlist.svg'"/>
            <span>{{ $t('li.document.ingestedList') }}</span>
          </template>
          <div class="row">
            <div class="col-2 section text-align-left pl-0 pt-3">
              <back-button
                :returnTo="`/${this.$store.getters.getInstance}/language-inteligence/${this.$route.params.id}`" />
              <select-docs :title="$t('li.document.selectDocument')" v-model="currentDocument"
                           :current-document="currentDocument" :documents="documents"
                           :enabled="!polling"/>
              <div class="mb-4"><h5>{{ $t('li.document.tools') }}</h5>
                <b-form-checkbox v-model="showTags">
                  {{ $t('li.document.showTags') }}
                </b-form-checkbox>
                <b-form-checkbox v-model="filterPassages">
                  {{ $t('li.document.filterPassages') }}
                </b-form-checkbox>
              </div>
              <div v-if="documentData.ontologies.length > 0" class="mb-4"><h5>{{ $t('li.document.filterCategories') }}</h5>
                <b-form-checkbox v-for="ontology in documentData.ontologies"
                                 v-bind:key="ontology.key"
                                 v-model="ontology.checked"
                                 class="green-checkbox"
                                 :value="true" :unchecked-value="false">
                  <!--@change="toggleCategory(ontology)"-->
                  {{ontology.name}}
                </b-form-checkbox>
              </div>
            </div>
            <div class="col-10 full-height">
              <div class="row section-row full-height">
                <div class="col-12 text-align-left pr-0">
                  <document-summary :current-document="currentDocument" :ontologies="documentData.ontologies"
                                    :passages="documentData.passages.length"/>

                  <div class="myproj-container padding-tabs">
                    <b-tabs content-class="">
                      <b-tab active>
                        <template v-slot:title>
                          <img :src="'/assets/images/icon_di_documentview.svg'"/>
                          <span>{{ $t('li.document.documentView') }}</span>
                        </template>
                        <div class="tags-visible">
                          <preloader v-if="polling" />
                          <div v-for="passage in documentData.passages" :key="passage.id">
                            <b-card v-show="hasVisibleTags(passage.tags, filterPassages, checkedOntologies)"
                                    class="mb-2  document-font  border-0" align="left">
                              <div class="margin-passages"
                                   v-html="passage.html"></div>
                            </b-card>
                          </div>
                        </div>
                      </b-tab>
                      <b-tab>
                        <template v-slot:title>
                          <img :src="'/assets/images/icon_di_graphs.svg'"/>
                          <span>{{ $t('li.document.graphs') }}</span>
                        </template>
                        <div class="recipe-menu tabs-menu">
                          <preloader v-if="polling" />
                          <b-card no-body style="border: none;">
                            <b-tabs pills card vertical>
                              <b-tab>
                                <template v-slot:title>
                                  <img :src="'/assets/images/icon_di_document.svg'"/>
                                  <span>{{ $t('li.document.document') }}</span>
                                </template>
                                <div>
                                  <tag-bar-chart v-if="documentData.barGraphData != null"
                                                 :tags="documentData.barGraphData"></tag-bar-chart>
                                </div>
                              </b-tab>
                              <b-tab>
                                <template v-slot:title>
                                  <img :src="'/assets/images/icon_di_tag.svg'"/>
                                  <span>{{ $t('li.document.tag') }}</span>
                                </template>
                                <grouped-tag-bar-chart v-if="documentData.groupedBarGraphData != null"
                                                       :ontologies="documentData.groupedBarGraphData"></grouped-tag-bar-chart>
                              </b-tab>
                              <b-tab>
                                <template v-slot:title>
                                  <img :src="'/assets/images/icon_di_explore.svg'"/>
                                  <span>{{ $t('li.document.explore') }}</span>
                                </template>
                                <div class="offset-md-2">
                                  <zoomable-sunburst-chart v-if="documentData.sunburstGraphData != null"
                                                           :tags="documentData.sunburstGraphData"></zoomable-sunburst-chart>
                                </div>
                              </b-tab>
                            </b-tabs>
                          </b-card>
                        </div>
                      </b-tab>
                    </b-tabs>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-tab>
        <!---------------------->
        <b-tab :disabled="documents.length < 2">
          <template v-slot:title>
            <img :src="'/assets/images/icon_di_documentcomparison.svg'"/>
            <span>{{ $t('li.document.documentComparison') }}</span>
          </template>
          <DocumentComparison v-if="documents.length > 1" :current-document="currentDocument"
                              :document-data="documentData"
                              :document-data-b="documentDataB"
                              :documents="documents" :polling="polling"
                              :updated-document="updatedDocument"
                              :progressive-comparison="true"/>
        </b-tab>
        <!---------------------->

        <b-tab :disabled="!thereAreMultiversionDocuments">
          <template v-slot:title>
            <img :src="'/assets/images/icon_di_progressivecomparison.svg'"/>
            <span>{{ $t('li.document.progressiveComparison') }}</span>
          </template>

          <DocumentComparison v-if="thereAreMultiversionDocuments"
                              :current-document="currentMultiversionDocument"
                              :document-data="versionData"
                              :document-data-b="versionDataB"
                              :documents="currentMultiversionDocument.versions" :polling="polling"
                              :updated-document="updatedMultiversionDocument"
                              :progressive-comparison="true"/>
        </b-tab>
        <b-tab disabled>
          <template v-slot:title>
            <img :src="'/assets/images/icon_di_decisiontree.svg'"/>
            <span>{{ $t('li.document.decisionTrees') }}</span>
          </template>
          <p>{{ $t('li.document.decisionTrees') }}</p>
        </b-tab>
        <b-tab disabled>
          <template v-slot:title>
            <img :src="'/assets/images/icon_di_whatsnew.svg'"/>
            <span>{{ $t('li.document.whatsNew') }}</span>
          </template>
          <p>{{ $t('li.document.whatsNew') }}</p>
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>
<script>

  import ZoomableSunburstChart from '@/modules/languageInteligence/components/ZoomableSunburstChart'
  import TagBarChart from '@/modules/languageInteligence/components/TagBarChart'
  import GroupedTagBarChart from '@/modules/languageInteligence/components/GroupedTagBarChart'
  import LanguageIntelligenceService from '@/modules/languageInteligence/services/LanguageIntelligenceService'
  import ProjectService from '@/modules/projects/services/ProjectService'
  import Preloader from "@/modules/common/components/Preloader";
  import LibraryService from '@/modules/library/services/LibraryService.js'
  import SelectDocs from "@/modules/languageInteligence/views/SelectDocs";
  import DocumentSummary from "@/modules/languageInteligence/views/DocumentSummary";
  import DocumentComparison from "@/modules/languageInteligence/views/DocumentComparison";
  import BackButton from "@/modules/common/components/BackButton.vue"

  class DocumentDataObject  {

  title = ""
  passages =  []
  ontologies = []
  polling = null
  sunburstGraphData = null
  barGraphData = null
  groupedBarGraphData = null
  name = null
  instance = null
  callback = null
  ready = false

  constructor(name, instance, callback) {
    this.name = name
    this.instance= instance
    this.callback= callback
  }

  getData = function(document, projectId) {
    let documentId = document.doc_id
    this.title = document.title
    this.passages = []
    this.clearGraphData()
    LanguageIntelligenceService.getDocument(this.instance,
      documentId, projectId).then(response => {

      if (response.data.status == "QUEUED") {
        if (this.polling == null) {
          this.pollData(document, projectId)
        }
      } else {
        clearInterval(this.polling)
        this.polling = null
        this.populatePassages(response)
        this.populateGraphData(response)
        this.ready = true
      }
    })
  }

  pollData = function(document, projectId) {
    this.polling= setInterval(
      (function(self) {         //Self-executing func which takes 'this' as self
        return function() {   //Return a function in the context of 'self'
          self.getData(document, projectId); //Thing you wanted to run as non-window 'this'
        }
      })(this),5000);
  }

  populateGraphData = function(response) {
    this.sunburstGraphData = response.data.graphData
    this.barGraphData = response.data.barChartData
    this.groupedBarGraphData= response.data.barChartGroupedData
  }

  populatePassages = function(response) {
    let $this = this
    $this.passages = response.data.taggedDocument.passages
    let ontologies = {}
    $this.passages.forEach(function (passage) {
      if (passage.tagging_data != null) {
        passage.tagging_data.forEach(function (tagging) {
          if (tagging.metatagName != undefined) {

            if (passage.tags == undefined) {
              passage.tags = []
            }
            passage.tags.push(tagging.metatagName)

            if (ontologies[tagging.metatagName] == undefined) {
              ontologies[tagging.metatagName] = {
                name: tagging.metatagName.replace('_', ' '),
                key: tagging.metatagName,
                data: tagging.ontologyData,
                checked: false
              }
            }
            ontologies[tagging.metatagName]['total'] = 1 + (ontologies[tagging.metatagName]['total'] || 0)
            ontologies[tagging.metatagName]['unique'] = 1 + (ontologies[tagging.metatagName]['unique'] || 0)
          }
        })
      }
    })
    $this.ontologies = Object.values(ontologies)
    $this.callback()
  }

  clearGraphData = function () {
    this.sunburstGraphData = null
    this.barGraphData = null
    this.groupedBarGraphData= null
  }
}
  const $ = require('jquery')
  window.$ = $

  export default {
    components:{
      DocumentComparison,
      DocumentSummary,
      SelectDocs,
      BackButton,
      'preloader': Preloader,
      'zoomable-sunburst-chart':ZoomableSunburstChart,
      'tag-bar-chart':TagBarChart,
      'grouped-tag-bar-chart':GroupedTagBarChart,
    },
    data() {
      return {
        preloader: '',
        documents:[],
        multiversionDocuments:[],
        showTags: false,
        filterPassages: false,
        currentMultiversionDocument: { title: 'Document', versions:[] },
        updatedMultiversionDocument: { title: 'Document', versions:[] },
        currentDocument: { title: 'Document' },
        updatedDocument: { title: 'Document' },
        documentData : new DocumentDataObject("documentData", this.$router.currentRoute.params.bot, this.documentPopulationCallback),
        documentDataB : new DocumentDataObject("documentDataB", this.$router.currentRoute.params.bot, this.documentPopulationCallback),
        versionData : new DocumentDataObject("versionData", this.$router.currentRoute.params.bot, this.documentPopulationCallback),
        versionDataB : new DocumentDataObject("versionDataB", this.$router.currentRoute.params.bot, this.documentPopulationCallback)
      }
    },
    methods: {
      documentPopulationCallback(){
        // this executes once the dom is completely updated, so we can avoid updating the check status before
        // the dom is updated
        this.$nextTick(()=> {
          this.selectAllFilters()
          this.showTags = true
        })
      },
      toggleCategory(ontology) {
        if (ontology.checked) {
          $('.' + ontology.key).removeClass("tags-invisible")
          $('.li-tagged-text.' + ontology.key).css("background", ontology.data['color-b'])
          $('.li-tagged-text.' + ontology.key).css("border-color", ontology.data['color-c'])
          $('.li-tag.' + ontology.key).css("background", ontology.data['color-a'])
        } else {
          $('.' + ontology.key).addClass("tags-invisible")
        }
      },
      selectAllFilters() {
        let page = this
        this.documentData.ontologies.forEach(function (ontology) {
          let checked = page.showTags
          ontology.checked = checked
        })
      },
      hasVisibleTags(tags, filter, checkedOntologies){
        if (!filter) {
          return true
        } else if (checkedOntologies.length == 0) {
          return false
        } else {
          const arr2Set = new Set(checkedOntologies);
          let thereAreCommonTags = tags.some(el => arr2Set.has(el));
          return thereAreCommonTags
        }
      },
      initialDocument() {
        var filters = {start: 0, quantity: 1}
        LibraryService.get(this.$router.currentRoute.params.bot, filters).then(
          ProjectService.get(this.$router.currentRoute.params.id, this.$router.currentRoute.params.bot, true).then(
            response => {
              this.documents = response.data.documents_data
              if (this.documents.length > 0) {
                this.currentDocument = this.documents[0]
                this.documents.forEach(document => {
                  if (document["previous_versions"] != undefined && document["previous_versions"].length >0) {
                    this.multiversionDocuments.push(document)
                  }
                })
                if (this.multiversionDocuments.length > 0) {
                  this.currentMultiversionDocument = this.multiversionDocuments[0]
                }
              }
            },
            error => {
              console.error(error)
            })
        )
      }
    },
    watch: {
      showTags: function () {
        this.selectAllFilters()
      },

      documentData: {
        deep: true,
        handler() {
          this.documentData.ontologies.forEach(ontology => this.toggleCategory(ontology))
        }
      },
      currentDocument: function () {
        this.documentData.getData(this.currentDocument, this.$route.params.id)
      },
      updatedDocument: function () {
        this.documentDataB.getData(this.updatedDocument, this.$route.params.id)
      },
    },

    computed: {
      thereAreMultiversionDocuments() {
        return this.multiversionDocuments != undefined && this.multiversionDocuments.length >0
      },
      polling() {
        return this.documentData.polling !=null || this.documentDataB.polling !=null
      },
      checkedOntologies() {
        let arr = []
        this.documentData.ontologies.forEach(ontology => {
          if (ontology.checked) {
            arr.push(ontology.key)
          }
        })
        return arr
      }
    },
    created() {
      // try to see if this solves the cookies problem
      // search the library before get the project data
      this.initialDocument()
    }
  }
</script>
<style lang="css" >
  .tags-visible .li-tagged-text{
    padding: 2px !important;
    border-radius: 10px !important;
  }
  .tags-visible .li-tagged-text span.li-tag{
    vertical-align: bottom !important;
    font-size:9px !important;
    margin-left:0px !important;
    padding: 3px !important;
    border-radius:10px !important;
  }
  .full-height{
    min-height:90vh;
  }
  .tabs-menu {
    margin-left: -35px;
  }

  .padding-tabs {
    margin-left: 10px;
  }

  .tags-visible .li-tagged-text {
    border: 1px solid;
    padding: 4px;
    border-radius: 3px;
    white-space: nowrap;
  }

  .tags-visible .li-tag {
    font-family: monospace;
    padding: 3px;
    margin-left: 5px;
    border-radius: 3px;
    color: #4b4b4b;
    text-transform: uppercase;
    font-size: x-small;
    vertical-align: middle;
  }

  .tags-invisible {
    border: none !important;
    background-color: transparent !important;
    padding: 0px !important;
  }

  .tags-invisible .li-tag {
    display: none;
  }

  .section {
    border-bottom: 1px solid #e1e1e1;
    border-right: 1px solid #e1e1e1;
    padding: 10px;
  }

  .section-row {
    border-bottom: 1px solid #e1e1e1;
    padding: 10px;
  }

  .no-padding-left {
    padding-left: 0px !important;
  }

  .navigation-bar {
    padding-bottom: 25px;
    padding-top: 25px;
  }

  .gray {
    background-color: #f8f8f8;
  }

  .language-intelligence-document-fonts {
    font-family: OpenSans;
    font-size: 16px;
    font-weight: 100;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #151515;
  }

  .document-font {
    line-height: 160%;
  }

  .custom-checkbox {
    color: #28a745;
    text-transform: capitalize;

  }

  .custom-control-label span, span.cursor-pointer {
    font-family: OpenSans;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--viridian);
  }

  .custom-control-label::before {
    border: 1px solid var(--viridian);
    border-radius: 6px;
  }

  .custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
    background-color: var(--viridian);
    border-radius: 6px;
  }

  .custom-control-input:checked + label.custom-control-label span,
  .custom-control.custom-checkbox .custom-control-input:checked + span.cursor-pointer {
    color: black;
  }
</style>