<template>
  <document-intelligence-layout>
    <template v-slot:leftSection>
      <select-docs :title="$t('li.document.initialDocument')" v-model="firstDoc"
                   :current-document="firstDoc"
                   :documents="documents"
                   :blacklisted-document="secondDoc"
                   :enabled="!polling"/>
      <br>
      <select-docs :title="$t('li.document.updatedDocument')" v-model="secondDoc"
                   :current-document="secondDoc"
                   :blacklisted-document="firstDoc"
                   :documents="documents" :enabled="!polling"/>
    </template>
    <template v-slot:upperSection>
      <b-row>
        <div class="col-6 section-comparison text-align-left pl-0 pt-3">
          <document-summary :current-document="firstDoc"
                            :ontologies="firstDocData.ontologies"
                            :passages="firstDocData.passages.length"/>

        </div>
        <div class="col-6 pt-3">
          <document-summary :current-document="secondDoc"
                            :ontologies="secondDocData.ontologies"
                            :passages="secondDocData.passages.length"/>
        </div>
      </b-row>
    </template>
    <template v-slot:lowerSection>
      <b-tabs content-class="">
        <b-tab active>
          <template v-slot:title>
            <img :src="'/assets/images/icon_di_documentview.svg'"/>
            <span>{{ $t('li.document.graphs') }}</span>
          </template>
          <div class="recipe-menu tabs-menu">
            <b-card no-body style="border: none;">
              <b-tabs pills card vertical>
                <b-tab>
                  <template v-slot:title>
                    <img :src="'/assets/images/icon_di_document.svg'"/>
                    <span>{{ $t('li.document.document') }}</span>
                  </template>
                  <div>
                    <comparative-tag-bar-chart
                            v-if="documentData.barGraphData != null && documentDataB.barGraphData != null"
                            :tags-a="documentData.barGraphData" :name-a="documentData.title"
                            :tags-b="documentDataB.barGraphData" :name-b="documentDataB.title"
                    ></comparative-tag-bar-chart>
                  </div>
                </b-tab>
                <b-tab>
                  <template v-slot:title>
                    <img :src="'/assets/images/icon_di_tag.svg'"/>
                    <span>{{ $t('li.document.tag') }}</span>
                  </template>
                  <comparative-grouped-tag-bar-chart
                          v-if="documentData.groupedBarGraphData !=null && documentDataB.groupedBarGraphData !=null"
                          :ontologies-a="documentData.groupedBarGraphData"
                          :ontologies-b="documentDataB.groupedBarGraphData"
                          :title-a="documentData.title"
                          :title-b="documentDataB.title"></comparative-grouped-tag-bar-chart>
                </b-tab>
                <b-tab>
                  <template v-slot:title>
                    <img :src="'/assets/images/icon_di_explore.svg'"/>
                    <span>{{ $t('li.document.explore') }}</span>
                  </template>
                  <div class="row">
                    <div class="col-6">
                      <zoomable-sunburst-chart v-if="documentData.sunburstGraphData != null"
                                               :tags="documentData.sunburstGraphData"
                                               :title="documentData.title"
                                               :size="300"></zoomable-sunburst-chart>
                    </div>
                    <div class="col-6">
                      <zoomable-sunburst-chart v-if="documentDataB.sunburstGraphData != null"
                                               :tags="documentDataB.sunburstGraphData"
                                               :title="documentDataB.title"
                                               :size="300"></zoomable-sunburst-chart>

                    </div>
                  </div>
                </b-tab>
              </b-tabs>
            </b-card>
          </div>
        </b-tab>
        <b-tab v-if="progressiveComparison">
          <template v-slot:title>
            <img :src="'/assets/images/icon_di_graphs.svg'"/>
            <span>{{ $t('li.document.progressiveComparison') }}</span>
          </template>
          <ProgressiveComparison
                  :first-version="firstDoc"
                  :second-version="secondDoc"
                  :first-version-data="documentData"
                  :second-version-data="documentDataB"></ProgressiveComparison>
        </b-tab>

      </b-tabs>

    </template>

  </document-intelligence-layout>
</template>
<script>
  import ComparativeGroupedTagBarChart from '@/modules/languageInteligence/components/ComparativeGroupedTagBarChart'
  import ComparativeTagBarChart from '@/modules/languageInteligence/components/ComparativeTagBarChart'
  import DocumentSummary from "@/modules/languageInteligence/views/DocumentSummary"
  import SelectDocs from "@/modules/languageInteligence/views/SelectDocs"
  import ZoomableSunburstChart from '@/modules/languageInteligence/components/ZoomableSunburstChart'
  import DocumentIntelligenceLayout from "@/modules/languageInteligence/views/DocumentIntelligenceLayout"
  import ProgressiveComparison from "@/modules/languageInteligence/views/ProgressiveComparison"

  export default {
    name: 'DocumentComparison',
    components: {
      ComparativeGroupedTagBarChart,
      ComparativeTagBarChart,
      DocumentSummary,
      SelectDocs,
      ZoomableSunburstChart,
      DocumentIntelligenceLayout,
      ProgressiveComparison
    },
    props: {
      currentDocument: {},
      documentData: {},
      documentDataB: {},
      documents: {},
      polling: {},
      updatedDocument: {},
      progressiveComparison:{}
    },
    data() {
      return {
        firstDoc: this.currentDocument,
        secondDoc: this.updatedDocument,
        firstDocData: this.documentData,
        secondDocData: this.documentDataB
      }
    },
    watch: {
      firstDoc: function () {
        this.firstDocData.getData(this.firstDoc, this.$route.params.id)
      },
      secondDoc: function () {
        this.secondDocData.getData(this.secondDoc, this.$route.params.id)
      }
    }
  }
</script>
<style lang="css">
  .full-height {
    min-height: 90vh;
  }

  .tabs-menu {
    margin-left: -35px;
  }

  .padding-tabs {
    margin-left: 10px;
  }

  .section {
    border-bottom: 1px solid #e1e1e1;
    border-right: 1px solid #e1e1e1;
    padding: 10px;
  }

  .section-row {
    border-bottom: 1px solid #e1e1e1;
    padding: 10px;
  }

  .section-comparison {
    border-right: 1px solid #e1e1e1;
    padding: 10px;
  }

</style>