<template>
  <div>
    <vue-plotly :data="data" :layout="layout" :options="options"/>
  </div>
</template>
<script>
  import VuePlotly from '@statnett/vue-plotly'

  export default {
    name: "TagBarChart",
    components: {
      VuePlotly
    },
    props:{
      tags:Object
    },
    data: function () {
      return {
        data: [],
        layout: {},
        options: {}
      }
    },
    created() {
      var data = [
        {
          "type": "bar",
          "x": this.tags.labels,
          "y": this.tags.y
        }]

      this.data = data

    }
  }
</script>
<style></style>