<template>
  <div>
    <b-form-radio-group
            id="btn-radios-1"
            v-model="selectedTag"
            :options="options"
            name="radios-btn-default"
            buttons
            button-variant="success"
    ></b-form-radio-group>

    <div class="p-1">
      <b>{{selectedTag}}</b> was tagged in {{secondVersionData.title}} in {{selectedTagPassages}} passages
    </div>
    <div class="tags-visible" :class="category.name">
      <div v-for="passage in secondVersionData.passages" :key="passage.id">
        <b-card v-show="hasTag(passage)" align="left"
                class="mb-2 mt-0 pt-0 no-imgs document-font  border-0 gray-bar" >
          <div v-html="passage.html"></div>
        </b-card>
      </div>

    </div>
  </div>
</template>
<script>

  const $ = require('jquery')
  window.$ = $

  export default {
    name: 'TaggedPassageComparison',
    props: {
      ontologyData:{},
      ontologyName:{},
      category: {},
      secondVersionData: {},
    },
    data() {
      return {
        selectedTag: {},
        options:[],
        selectedTagPassages:1
      }
    },
    created() {
      let page = this
      this.category["tags"].forEach(tag => {
        page.options.push({text: tag["name"], value:tag["name"]})
      })
      page.selectedTag = page.options[0]["value"]
    },
    watch: {
      selectedTag: function () {
        let ontologyColorData = this.ontologyData['data']
        $('.li-tagged-text').addClass("tags-invisible")
        let selectedTag = this.selectedTag
        $(`span[title="${selectedTag}"]`).parent().removeClass("tags-invisible")
        $(`span[title="${selectedTag}"]`).parent().css("background-color", ontologyColorData['color-b'])
        $(`span[title="${selectedTag}"]`).parent().css("border-color", ontologyColorData['color-c'])
        $(`span[title="${selectedTag}"]`).css("background", ontologyColorData['color-a'])

      }
    },
    methods: {

      hasTag(passage){
        let tags = passage["tagging_data"].map(a => a["spanPreferedTerm"]);
        return tags.includes(this.selectedTag)
      }
    }
  }
</script>
<style lang="css">
  .gray-bar {
    border-left: 3px solid #999999 !important;
  }

  .no-imgs img {
    display: none
  }
  .tags-visible .li-tagged-text{
    padding: 2px !important;
    border-radius: 10px !important;
  }
  .tags-visible .li-tagged-text span.li-tag{
    vertical-align: bottom !important;
    font-size:9px !important;
    margin-left:0px !important;
    padding: 3px !important;
    border-radius:10px !important;
  }
  .full-height{
    min-height:90vh;
  }

  .tags-visible .li-tagged-text {
    border: 1px solid;
    padding: 4px;
    border-radius: 3px;
    white-space: nowrap;
  }

  .tags-visible .li-tag {
    font-family: monospace;
    padding: 3px;
    margin-left: 5px;
    border-radius: 3px;
    color: #4b4b4b;
    text-transform: uppercase;
    font-size: x-small;
    vertical-align: middle;
  }

  .tags-invisible {
    border: none !important;
    background-color: transparent !important;
    padding: 0px !important;
  }

  .tags-invisible .li-tag {
    display: none;
  }
  .btn-group > .btn:not(.dropdown-toggle), .btn-group > .btn-group:not(:last-child) > .btn {
    border-top-right-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
    border-top-left-radius: 10px !important;
    border-bottom-left-radius: 10px !important;
    margin: 10px !important;
  }
</style>